<template>
  <ScreenWidthProvider
    id="startpage-categories"
    v-slot="{ isWider, isNarrower }"
    class="w-12/12 mb-12"
  >
    <div
      v-if="isWider('md') && filteredCategories.length > 0"
      class="flex flex-wrap -mx-4"
    >
      <div
        v-for="(category, i) in filteredCategories"
        :key="i"
        class="category-item px-4 grow"
      >
        <button
          class="btn btn-beige btn-lg btn-block rounded-3xl w-full py-3"
          @click="() => navigateTo(category?.url)"
        >
          {{ category?.name }}
        </button>
      </div>
    </div>
    <template v-if="isNarrower('md')">
      <div class="flex flex-wrap justify-center">
        <div
          v-for="(category, i) in filteredCategories"
          :key="i"
          class="category-item px-1 w-6/12 md:w-4/12 lg:w-3/12"
        >
          <button
            class="btn btn-beige btn-lg btn-block rounded-3xl mb-2 w-full"
            @click="() => navigateTo(category?.url)"
          >
            {{ category?.name }}
          </button>
        </div>
      </div>
    </template>
  </ScreenWidthProvider>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  data () {
    return {
      sizeListArticleImg: [{
        maxWidth: null,
        size: 625 * 3,
      }, {
        maxWidth: 920,
        size: 920 * 1.5,
      }],
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    ...mapState(useRootStore, {
      categories: 'startCategories',
      locale: 'locale',
    }),

    filteredCategories () {
      return (this.categories || [])
        .filter(category => Boolean(category) && (this.locale !== 'no' || !category.url.includes('/bussreiser')))
    },

    categoriesSlide () {
      return this.filteredCategories
        .map(category => ({
          image: category.picture_cloudinary,
          type: 'sized',
          title: category.name,
          link: {
            href: category?.url || '#',
          },
        }))
    },
  },
})
</script>
